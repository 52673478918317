<template>
    <div>

        <breadcrumb name="Mansour Plus" title="Mansour Plus Link" subtitle="Edit"/>
        <div class="body">
            <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
            <div>
                <div class="english">
                    <vs-input label="Mansour Plus Link*" v-model="mPlus.value"
                              size="medium"
                              class="input" :danger="flags.link" danger-text="This field is mandatory"/>
                </div>
            </div>
            <div>
                <div class="clr"/>
            </div>

            <div class="optionBtns">
                <vs-button :disabled="invalidForm" class="mr-5 save" icon-pack="feather" icon="icon-save"
                           @click="confirmSaveData">Save
                </vs-button>
                <vs-button class="ml-5 cancel" type="border" color="primary" @click="getMPlus">
                    Reset
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ChevronsRightIcon, HomeIcon } from 'vue-feather-icons';
    import Breadcrumb from "../../../../components/general/breadcrumb";

    export default {
        name: 'mansour-plus',
        components: {Breadcrumb, HomeIcon, ChevronsRightIcon },

        data() {
            return {
                isEditing: false,
                mPlus: {
                    id: null,
                    key: 'mansourplus',
                    value: '',
                    type: null,
                    mobile_active: null,
                    web_active: null
                },
                flags: {
                    link: false
                }
            };
        },
        methods: {
            confirmSaveData() {
                this.$vs.dialog({
                    title: 'Confirm',
                    text: 'You are going to save these data!',
                    color: 'primary',
                    accept: () => this.updateData(),
                    type: 'confirm'
                });
            },
            updateData() {
                this.$vs.loading();

                let payload = {
                    value: this.mPlus.value
                };

                this.$httpAbout.post(`config/update/${this.mPlus.id}`, payload)
                    .then(r => {
                        console.log(r);
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: 'Saved',
                            text: 'Data is Saved Successfully',
                            color: 'primary'
                        });
                    })
                    .catch(() => {
                        //console.log(e);
                        this.$vs.loading.close();
                        this.$vs.notify({
                            title: 'Error',
                            text: 'Error is occurred!\nCouldn\'t Save Data!',
                            color: 'danger'
                        });
                    });

                this.isEditing = false;
                this.getMPlus();
            },
            getMPlus() {
                this.$vs.loading();
                this.$httpAbout.get(`config/by-key/${this.mPlus.key}`)
                    .then(r => {
                        this.mPlus = r.data.data;
                        console.log(this.mPlus);
                        this.$vs.loading.close();
                    })
                    .catch(() => {
                        //console.log(e);
                        this.$vs.notify({
                            title: 'Error',
                            text: 'Couldn\'t Load Data!',
                            color: 'danger'
                        });
                        this.$vs.loading.close();
                    });
            }
        },
        mounted() {
            this.getMPlus();
        },
        computed: {
            invalidForm() {
                if (this.mPlus.value === '') {
                    return true;
                }
                return false;
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "../../../basic-styles/mixins";

    %flex {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .img-fluid {
        border: 1px solid #00000030;
        height: auto;
        width: 200px;
        max-width: 100%;
        border-radius: 20px;
        @include margin-x(20px);
    }

    .images {
        @include margin-y(30px);
        text-align: center;
    }

    .optionBtns {
        @include margin-x(20px);
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .save {
            position: relative;
            left: 25px;
        }
    }

    .input {
        width: 100%;
        @include margin-y(30px)
    }

    .arabic, .english {
        @extend %flex;
        flex-flow: column;
        width: 100%;
        padding: 20px;
    }

    h1 {
        margin-bottom: 25px;
    }

    .inputs {
        @extend %flex;
    }

    ul {
        li {
            display: inline;
        }
    }

    .clr {
        clear: both;
    }

    $primaryColor: #054f7c;

    .breadcrumb {
        margin: 20px;
    }

    .back-to {
        cursor: pointer;
        color: $primaryColor;
        font-weight: bold;
    }

    .main-component {
        color: $primaryColor;
    }

    .required {
        color: red;
        font-size: 1.5rem;
    }

    .mandatory {
        color: red;
    }

    .arabic, .english {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }

    .error {
        color: red;
        text-align: center;
        margin-bottom: 20px;
    }

    .body {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
    }

    .img-uploader {
        height: 200px;
        max-width: 100%;
        width: auto;
        border-radius: 20px;
        padding: 10px
    }

    .custom-class {
        color: $primaryColor;
    }

    .title {
        font-size: 2rem;
        font-weight: 400;
    }

    .separator {
        font-size: 2rem;
        font-weight: 100;
        color: #ccc;
    }

    .label {
        color: #000;
    }

</style>
